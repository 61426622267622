@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bad+Script&family=Borel&family=Fjalla+One&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,700&family=Mooli&family=Passion+One:wght@900&family=Russo+One&family=Young+Serif&display=swap');


.anton{
    font-family: 'anton';
  font-size: 25px;
  color: #0b2149 ;
}
.red-Bg{
    background-color: rgb(161, 0, 0);
}
.red-Bg:hover{
    background-color: rgb(192, 4, 4);
}
.popOpener{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 100;
}
.home_description-header{
    font-size:20px;
  font-family: 'Young Serif', serif;


  }